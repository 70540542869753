import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import dayjs from 'dayjs';



const MassiveImportModal = ({ show, onClose, customers, onImport }) => {
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [importedRepairs, setImportedRepairs] = useState([]);
  const [fileName, setFileName] = useState('');
  const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Converti tutte le chiavi in minuscolo per evitare problemi di maiuscole/minuscole
      const normalizedJsonData = jsonData.map((row) => {
        const normalizedRow = {};
        Object.keys(row).forEach((key) => {
          normalizedRow[key.toLowerCase()] = row[key];
        });
        return normalizedRow;
      });

      // Elaborare i dati dal file Excel e prepararli per la visualizzazione
      const repairs = normalizedJsonData.map((row) => {
        let parsedDate = null;
        if (row['data']) {
          const isDateNumber = !isNaN(row['data']);
          parsedDate = isDateNumber
            ? dayjs(XLSX.SSF.format('yyyy-mm-dd', row['data'])).format('YYYY-MM-DD')
            : row['data'];
        }

        return {
          entry_date: parsedDate || new Date().toISOString().split('T')[0],
          description: row['descrizione'] || '',
          externalCode: row['ticket'] || '',
        };
      });
      console.log('stampa riparazioni da importare', { repairs });
      setImportedRepairs(repairs);
      setFileName(file.name);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedRepairs = [...importedRepairs];
    updatedRepairs[index][field] = value;
    setImportedRepairs(updatedRepairs);
  };

  const handleImport = async () => {
    if (!selectedCustomer) {
      alert('Selezionare un cliente per l\'importazione.');
      return;
    }

    try {
      await axios.post(`${serverUrl}/repairs/import`, {
        id_customer: selectedCustomer,
        repairs: importedRepairs,
      });

      alert('Riparazioni importate con successo!');
      onImport();
      onClose();
    } catch (error) {
      console.error('Errore durante l\'importazione delle riparazioni:', error);
      alert('Errore durante l\'importazione delle riparazioni.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-full max-w-3xl p-8 rounded-lg shadow-xl">
        <h2 className="text-2xl font-bold mb-8 text-center">Importazione Massiva Riparazioni</h2>

        {/* Selezione Cliente */}
        <div className="mb-8">
          <label className="block text-lg font-medium mb-3 text-gray-800">Seleziona Cliente</label>
          <select
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
            value={selectedCustomer}
            onChange={handleCustomerChange}
          >
            <option value="">-- Seleziona Cliente --</option>
            {customers.map((customer) => (
              <option key={customer.id_customer} value={customer.id_customer}>
                {customer.companyName}
              </option>
            ))}
          </select>
        </div>

        {/* Caricamento File Excel */}
        <div className="mb-8">
          <label className="block text-lg font-medium mb-3 text-gray-800">Carica File Excel</label>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
          />
          {fileName && <p className="mt-2 text-gray-600">File caricato: {fileName}</p>}
        </div>

        {/* Visualizzazione e Modifica Dati Importati */}
        {importedRepairs.length > 0 && (
          <div className="overflow-y-auto max-h-[400px] mb-8">
            <table className="min-w-full border-collapse bg-white shadow-lg">
              <thead className="sticky top-0 bg-gray-100 z-10">
                <tr>
                  <th className="border border-gray-300 p-3 text-left text-gray-700">Data di Ingresso</th>
                  <th className="border border-gray-300 p-3 text-left text-gray-700">Codice Esterno</th>
                  <th className="border border-gray-300 p-3 text-left text-gray-700">Descrizione (Tiket)</th>
                </tr>
              </thead>
              <tbody>
                {importedRepairs.map((repair, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="border border-gray-300 p-3">
                      <input
                        type="date"
                        value={repair.entry_date}
                        onChange={(e) => handleFieldChange(index, 'entry_date', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
                      />
                    </td>
                    <td className="border border-gray-300 p-3">
                      <input
                        type="text"
                        value={repair.externalCode}
                        onChange={(e) => handleFieldChange(index, 'externalCode', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
                      />
                    </td>
                    <td className="border border-gray-300 p-3">
                      <input
                        type="text"
                        value={repair.description}
                        onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Pulsante di Importazione */}
        <div className="flex justify-end gap-6">
          <button
            onClick={handleImport}
            className="bg-green-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300 transition-all"
          >
            Importa Riparazioni
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300 transition-all"
          >
            Chiudi
          </button>
        </div>
      </div>
    </div>
  );
};

export default MassiveImportModal;
