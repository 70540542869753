import React, { useState } from 'react';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const DDTDataInput = ({ documentLines, setDocumentLines }) => {
  const [inputData, setInputData] = useState({
    quantity: 1,
    repairCode: '',
    description: '',
    price: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };

  // Handle keypress for repair code input
  const handleRepairCodeKeyPress = async (e) => {
    if (e.key === 'Enter') {
      try {
        const response = await fetch(`${serverUrl}/repairs/${inputData.repairCode}`);
        if (response.ok) {
          const repair = await response.json();

          // Check if the repair is already "Consegnata"
          if (repair.current_status === 'Consegnata') {
            setErrorMessage(`La riparazione con codice ${repair.id_repair} è già stata consegnata e non può essere aggiunta al DDT.`);
            return;
          }

          // If the repair is not "Consegnata", proceed to add it to the input data
          setInputData((prevInput) => ({
            ...prevInput,
            description: `cod Rip ${repair.id_repair}  ${repair.description}`,
            price: repair.public_price,
          }));

          setErrorMessage(''); // Clear any previous error messages
        } else {
          setErrorMessage('Riparazione non trovata.');
        }
      } catch (error) {
        console.error('Error fetching repair data:', error);
        setErrorMessage('Errore durante la ricerca della riparazione.');
      }
    }
  };

  // Handle adding a line item
  const handleAddLine = () => {
    setDocumentLines((prevLines) => [...prevLines, inputData]);
    // Reset input fields after adding
    setInputData({ quantity: 1, repairCode: '', description: '', price: '' });
  };

  // Handle removing a line item
  const handleRemoveLine = (index) => {
    setDocumentLines((prevLines) => prevLines.filter((_, i) => i !== index));
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <input
          type="number"
          name="quantity"
          value={inputData.quantity}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Quantità"
        />
        <input
          type="text"
          name="repairCode"
          value={inputData.repairCode}
          onChange={handleInputChange}
          onKeyPress={handleRepairCodeKeyPress}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Codice Riparazione"
        />
        <input
          type="text"
          name="description"
          value={inputData.description}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Descrizione"
        />
        <input
          type="text"
          name="price"
          value={inputData.price}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Prezzo"
        />
        <button
          onClick={handleAddLine}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 mt-2 md:mt-0"
        >
          Aggiungi
        </button>
      </div>

      {/* Error message display */}
      {errorMessage && (
        <div className="text-red-500 mb-4">
          {errorMessage}
        </div>
      )}

      {/* Table to display added lines */}
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="text-left p-4 border-b">Quantità</th>
            <th className="text-left p-4 border-b">Descrizione</th>
            <th className="text-left p-4 border-b">Prezzo</th>
            <th className="text-left p-4 border-b">Azione</th>
          </tr>
        </thead>
        <tbody>
          {documentLines.map((line, index) => (
            <tr key={index} className="hover:bg-gray-50 transition duration-150">
              <td className="p-4 border-b">{line.quantity}</td>
              <td className="p-4 border-b">{line.description}</td>
              <td className="p-4 border-b">€ {line.price}</td>
              <td className="p-4 border-b">
                <button
                  onClick={() => handleRemoveLine(index)}
                  className="text-red-600 hover:text-red-800 transition duration-300"
                >
                  🗑️
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DDTDataInput;
