import setup from '../config/setup.json';

export const generateInvoiceXML = (invoiceData, customerData) => {
  const { name, address, city, province, cap, vatInfo, iva } = setup.companyInfo;

  // Verifica che le note siano disponibili
  if (!invoiceData.notes) {
    console.error("Nessuna nota presente per estrarre i dati DDT.");
    return;
  }

  // Funzione per estrarre i dati DDT dalle note
  const extractDDTData = (notes) => {
    console.log("Note Fattura:", notes);
    const ddtPattern = /DDT (\d+) del ([\d/]+)/g; // Regex per il formato delle note
    let match;
    const ddtReferences = [];
  
    while ((match = ddtPattern.exec(notes)) !== null) {
      // Estrai la data e convertila nel formato corretto YYYY-MM-DD
      const [day, month, year] = match[2].split('/');
      const formattedDate = `${year}-${month}-${day}`;
  
      ddtReferences.push({
        numeroDDT: match[1],
        dataDDT: formattedDate,
        riferimentoNumeroLinea: [] // Lascia vuoto se non hai dettagli delle linee
      });
    }
  
    return ddtReferences;
  };
  
  // Estrazione dei dati DDT dalle note
  const ddtReferences = extractDDTData(invoiceData.notes);

  if (ddtReferences.length === 0) {
    console.error("Nessun DDT trovato nelle note.");
    return;
  }

  // Generazione dell'XML
  const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
<ns0:FatturaElettronica xmlns:ns0="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" versione="FPR12" xsi:schemaLocation="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2 https://www.fatturapa.gov.it/export/documenti/fatturapa/v1.2.1/Schema_del_file_xml_FatturaPA_versione_1.2.1a.xsd">
  <FatturaElettronicaHeader>
    <DatiTrasmissione>
      <IdTrasmittente>
        <IdPaese>IT</IdPaese>
        <IdCodice>${vatInfo}</IdCodice>
      </IdTrasmittente>
      <ProgressivoInvio>00001</ProgressivoInvio>
      <FormatoTrasmissione>FPR12</FormatoTrasmissione>
      <CodiceDestinatario>${customerData.codiceSdi || '0000000'}</CodiceDestinatario>
    </DatiTrasmissione>
    <CedentePrestatore>
      <DatiAnagrafici>
        <IdFiscaleIVA>
          <IdPaese>IT</IdPaese>
          <IdCodice>${vatInfo}</IdCodice>
        </IdFiscaleIVA>
        <CodiceFiscale>${vatInfo}</CodiceFiscale>
        <Anagrafica>
          <Denominazione>${name}</Denominazione>
        </Anagrafica>
        <RegimeFiscale>RF01</RegimeFiscale>
      </DatiAnagrafici>
      <Sede>
        <Indirizzo>${address}</Indirizzo>
        <CAP>${cap}</CAP>
        <Comune>${city}</Comune>
        <Provincia>${province}</Provincia>
        <Nazione>IT</Nazione>
      </Sede>
    </CedentePrestatore>
    <CessionarioCommittente>
      <DatiAnagrafici>
        <IdFiscaleIVA>
          <IdPaese>IT</IdPaese>
          <IdCodice>${customerData.vatNumber}</IdCodice>
        </IdFiscaleIVA>
        <Anagrafica>
          <Denominazione>${customerData.companyName}</Denominazione>
        </Anagrafica>
      </DatiAnagrafici>
      <Sede>
        <Indirizzo>${customerData.address}</Indirizzo>
        <CAP>${customerData.cap}</CAP>
        <Comune>${customerData.citta}</Comune>
        <Provincia>${customerData.provincia}</Provincia>
        <Nazione>IT</Nazione>
      </Sede>
    </CessionarioCommittente>
  </FatturaElettronicaHeader>
  <FatturaElettronicaBody>
    <DatiGenerali>
      <DatiGeneraliDocumento>
        <TipoDocumento>TD01</TipoDocumento>
        <Divisa>EUR</Divisa>
        <Data>${invoiceData.creation_date || '2024-01-01'}</Data>
        <Numero>${invoiceData.document_number}</Numero>
        <ImportoTotaleDocumento>${parseFloat(invoiceData.total_price).toFixed(2)}</ImportoTotaleDocumento>
        <Causale>Fattura</Causale>
      </DatiGeneraliDocumento>
    </DatiGenerali>
    
    <DatiBeniServizi>
      ${invoiceData.lines.map((line, index) => `
      <DettaglioLinee>
        <NumeroLinea>${index + 1}</NumeroLinea>
        <Descrizione>${line.description}</Descrizione>
        <Quantita>${parseFloat(line.quantity).toFixed(2)}</Quantita>
        <PrezzoUnitario>${parseFloat(line.price).toFixed(2)}</PrezzoUnitario>
        <PrezzoTotale>${(line.price * line.quantity).toFixed(2)}</PrezzoTotale>
        <AliquotaIVA>${parseFloat(iva).toFixed(2)}</AliquotaIVA>
      </DettaglioLinee>`).join('')}
      <DatiRiepilogo>
        <AliquotaIVA>${parseFloat(iva).toFixed(2)}</AliquotaIVA>
        <ImponibileImporto>${parseFloat(invoiceData.price).toFixed(2)}</ImponibileImporto>
        <Imposta>${parseFloat(invoiceData.vat).toFixed(2)}</Imposta>
        <EsigibilitaIVA>I</EsigibilitaIVA>
      </DatiRiepilogo>
    </DatiBeniServizi>

    <DatiPagamento>
      <CondizioniPagamento>TP01</CondizioniPagamento>
      <DettaglioPagamento>
        <ModalitaPagamento>${invoiceData.codePaymentXml || 'MP01'}</ModalitaPagamento>
        <ImportoPagamento>${parseFloat(invoiceData.total_price).toFixed(2)}</ImportoPagamento>
      </DettaglioPagamento>
    </DatiPagamento>
  </FatturaElettronicaBody>
</ns0:FatturaElettronica>`;

  return xmlData;
};
