// DDTPDF.js
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import setup from '../config/setup.json';

export const generateDDTPDF = (documentDetails, customerData, inputData) => {
  const doc = new jsPDF();

  // Header - Mittente
  const { name, address, city, country, vatInfo } = setup.companyInfo;

  doc.setFontSize(14);
  doc.text(name, 10, 10);
  doc.setFontSize(12);
  doc.text(address, 10, 15);
  doc.text(city, 10, 20);
  doc.text(country, 10, 25);
  doc.text(vatInfo, 10, 30);

  // Customer Info
  doc.setFontSize(12);
  doc.text("Destinatario:", 10, 50);
  doc.text(`${customerData.companyName || 'undefined'}`, 10, 55);
  doc.text(`${customerData.address || 'undefined'}`, 10, 60);
  doc.text(`${customerData.cap || 'undefined'} ${customerData.citta || 'undefined'} ${customerData.provincia || 'undefined'} ${customerData.nazione || 'undefined'}`, 10, 65);
  doc.text(`Telefono: ${customerData.phone || 'undefined'}`, 10, 70);
  doc.text(`Email: ${customerData.email || 'undefined'}`, 10, 75);
  if (customerData.pec) {
    doc.text(`PEC: ${customerData.pec}`, 10, 80);
  }

  // Document Details
  doc.text("Tipo Documento: DDT", 140, 50);
  doc.text(`Nr: ${documentDetails.document_number}`, 140, 55);
  doc.text(`Data: ${documentDetails.creation_date}`, 140, 60);
  doc.text(`Causale Trasporto: ${documentDetails.transport_reason}`, 140, 65);
  doc.text(`Aspetto Esteriore Dei Beni: ${documentDetails.appearance_of_goods}`, 140, 70);
  doc.text(`N. Colli: ${documentDetails.number_of_packages}`, 140, 75);
  doc.text(`Peso Kg: ${documentDetails.weight}`, 140, 80);
  doc.text(`Porto: ${documentDetails.shipment_port}`, 140, 85);

  // Table - Document Lines
  const tableColumnHeaders = ["Codice", "Descrizione", "Prezzo", "Quantità"];
  const tableRows = [];

  inputData.forEach((line) => {
    const row = [
      line.repairCode || "",
      line.description || "",
      line.price ? `€ ${parseFloat(line.price).toFixed(2)}` : "N/A",
      line.quantity || "1",
    ];
    tableRows.push(row);
  });

  doc.autoTable({
    startY: 100,
    head: [tableColumnHeaders],
    body: tableRows,
    theme: 'grid',
    headStyles: { fillColor: [0, 123, 255] },
    margin: { top: 10 },
  });

  // Footer
  const finalY = doc.autoTable.previous.finalY;
  doc.text("Note:", 10, finalY + 10);
  doc.text(`${documentDetails.notes || ""}`, 10, finalY + 20);

  doc.text("Trasporto a cura del:", 10, finalY + 40);
  doc.text(`${documentDetails.transport_by}`, 60, finalY + 40);

  doc.text("Firma Mittente", 10, finalY + 60);
  doc.text("__________________", 10, finalY + 65);

  doc.text("Firma Vettore", 80, finalY + 60);
  doc.text("__________________", 80, finalY + 65);

  doc.text("Firma Destinatario", 150, finalY + 60);
  doc.text("__________________", 150, finalY + 65);

  // Open PDF in a new tab for user action
  return doc.output('blob');
};