import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import ShowStates from './ShowStates';

const RepairTable = ({ repairs, toggleSelectRepair, selectedRepairs, showImageInLarge, openModal, handleDelete }) => {
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [showStateModal, setShowStateModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [repairToDelete, setRepairToDelete] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle right-click and open context menu
  const handleContextMenu = (e, repair) => {
    e.preventDefault();
    setSelectedRepair(repair);
    setContextMenu({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    });
  };

  // Function to close context menu
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Function to open the ShowStates modal
  const handleViewStates = () => {
    setShowStateModal(true);
    handleCloseContextMenu();
  };

  // Function to close the ShowStates modal
  const handleCloseStateModal = () => {
    setShowStateModal(false);
  };

  // Function to send selected repairs to DDTPage
  const handleSendToDDT = () => {
    if (selectedRepairs.length > 0) {
      const hasConsegnata = repairs.some(
        (repair) => selectedRepairs.includes(repair.id_repair) && repair.current_status === 'Consegnata'
      );

      if (hasConsegnata) {
        alert('Una o più riparazioni selezionate sono già "Consegnate" e non possono essere inviate a DDT.');
        return;
      }

      const selectedRepairsData = repairs.filter((repair) => selectedRepairs.includes(repair.id_repair)).map((repair) => ({
        repairCode: repair.id_repair,
        quantity: 1,
        description: `Riparazione ${repair.id_repair} ${repair.description}`,
        price: repair.public_price,
      }));

      navigate('/ddt', { state: { selectedRepairs: selectedRepairsData } });
      handleCloseContextMenu();
    } else {
      console.error('No repairs selected to send to DDT.');
    }
  };

  // Function to send selected repairs to InternalDischargePage
  const handleSendToInternalDischarge = () => {
    if (selectedRepairs.length > 0) {
      const hasConsegnata = repairs.some(
        (repair) => selectedRepairs.includes(repair.id_repair) && repair.current_status === 'Consegnata'
      );

      if (hasConsegnata) {
        alert('Una o più riparazioni selezionate sono già "Consegnate" e non possono essere inviate a Scarico Interno.');
        return;
      }

      const selectedRepairsData = repairs.filter((repair) => selectedRepairs.includes(repair.id_repair)).map((repair) => ({
        repairCode: repair.id_repair,
        quantity: 1,
        description: `Riparazione ${repair.id_repair} ${repair.description}`,
        price: repair.public_price,
      }));

      navigate('/internalDischarge', { state: { selectedRepairs: selectedRepairsData } });
      handleCloseContextMenu();
    } else {
      console.error('No repairs selected to send to Internal Discharge.');
    }
  };

  // Function to handle delete confirmation
  const handleDeleteConfirmation = (repairId) => {
    setRepairToDelete(repairId);
    setShowDeleteConfirmation(true);
  };

  // Function to confirm delete action
  const confirmDelete = () => {
    handleDelete(repairToDelete);
    setShowDeleteConfirmation(false);
    setRepairToDelete(null);
  };

  // Function to cancel delete action
  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setRepairToDelete(null);
  };

  return (
    <div className="  p-4 bg-white rounded-lg shadow-lg">
      {/* Contenitore scrollabile con altezza massima maggiore */}
      <div className="overflow-y-auto max-h-[800px]">
        <table className="min-w-full bg-white border-collapse border border-gray-300 relative">
          <thead className="sticky top-0 bg-gray-100">
            <tr>
              <th className="px-4 py-2 border-b border-r border-gray-300 text-left text-sm font-semibold text-gray-600">Codice</th>
              <th className="px-4 py-2 border-b border-r border-gray-300 text-left text-sm font-semibold text-gray-600">Codice Esterno</th>
              <th className="px-4 py-2 border-b border-r border-gray-300 text-left text-sm font-semibold text-gray-600">Descrizione</th>
              <th className="px-4 py-2 border-b border-r border-gray-300 text-left text-sm font-semibold text-gray-600">Data Accettazione</th>
              <th className="px-4 py-2 border-b border-r border-gray-300 text-left text-sm font-semibold text-gray-600">Status</th>
              <th className="px-4 py-2 border-b border-r border-gray-300 text-left text-sm font-semibold text-gray-600">Immagine</th>
              <th className="px-4 py-2 border-b border-r border-gray-300 text-left text-sm font-semibold text-gray-600">Seleziona</th>
              <th className="px-4 py-2 border-b text-left text-sm font-semibold text-gray-600">Azioni</th>
            </tr>
          </thead>
          <tbody>
            {repairs.map((repair) => (
              <tr key={repair.id_repair} onContextMenu={(e) => handleContextMenu(e, repair)} className="hover:bg-gray-50">
                <td className="px-4 py-2 border-b border-r border-gray-300 text-sm text-gray-700">{repair.id_repair}</td>
                <td className="px-4 py-2 border-b border-r border-gray-300 text-sm text-gray-700">{repair.externalCode}</td>
                <td className="px-4 py-2 border-b border-r border-gray-300 text-sm text-gray-700">{repair.description}</td>
                <td className="px-4 py-2 border-b border-r border-gray-300 text-sm text-gray-700">{repair.entry_date ? dayjs(repair.entry_date).format('DD-MM-YYYY') : 'N/A'}</td>
                <td className="px-4 py-2 border-b border-r border-gray-300 text-sm text-gray-700">{repair.current_status}</td>
                <td className="px-4 py-2 border-b border-r border-gray-300 text-sm text-gray-700">
                  {repair.pathPhoto && (
                    <img
                      src={`${process.env.REACT_APP_SERVER_URL}${repair.pathPhoto}`}
                      alt="Repair"
                      className="w-16 h-16 object-cover rounded-lg cursor-pointer"
                      onClick={() => showImageInLarge(`${process.env.REACT_APP_SERVER_URL}${repair.pathPhoto}`)}
                    />
                  )}
                </td>
                <td className="px-4 py-2 border-b border-r border-gray-300 text-sm text-center">
                  <input
                    type="checkbox"
                    checked={selectedRepairs.includes(repair.id_repair)}
                    onChange={() => toggleSelectRepair(repair.id_repair)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                </td>
                <td className="px-4 py-2 border-b text-sm text-center">
                  <button className="text-blue-500 hover:text-blue-700 mx-1" onClick={() => openModal(repair)}>
                    ✏️
                  </button>
                  <button className="text-red-500 hover:text-red-700 mx-1" onClick={() => handleDeleteConfirmation(repair.id_repair)}>
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="8" className="px-4 py-2 text-sm font-medium text-gray-700 text-right">
                Righe totali: {repairs.length}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
  
      {/* Context menu */}
      {contextMenu && (
        <div
          className="fixed z-10 w-48 bg-white border border-gray-300 rounded-lg shadow-lg"
          style={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
          onMouseLeave={handleCloseContextMenu}
        >
          <ul className="py-1">
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={handleViewStates}>View Statuses</li>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={handleSendToDDT}>Invia a DDT</li>
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={handleSendToInternalDischarge}>Invia a Scarico Interno</li>
          </ul>
        </div>
      )}
  
      {/* Modal for viewing statuses */}
      <ShowStates
        show={showStateModal}
        handleClose={handleCloseStateModal}
        repairId={selectedRepair ? selectedRepair.id_repair : null}
      />
  
      {/* Delete confirmation modal */}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="mb-4">Sei sicuro di voler cancellare il tiket?</p>
            <div className="flex justify-end gap-3">
              <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300" onClick={confirmDelete}>
                Sì, Cancella
              </button>
              <button className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-500 transition duration-300" onClick={cancelDelete}>
                Annulla
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
  
};

export default RepairTable;
