// InternalDischargePDF.js
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import setup from '../config/setup.json';

export const generateInternalDischargePDF = (documentDetails, customerData, inputData) => {
  const doc = new jsPDF();

  // Header - Mittente
  const { name, address, city, country, vatInfo } = setup.companyInfo;

  doc.setFontSize(14);
  doc.text(name, 10, 10);
  doc.setFontSize(12);
  doc.text(address, 10, 15);
  doc.text(city, 10, 20);
  doc.text(country, 10, 25);
  doc.text(vatInfo, 10, 30);

  // Customer Info
  doc.setFontSize(12);
  doc.text("Intestatario", 10, 50);
  doc.text(`${customerData.companyName || 'undefined'}`, 10, 55);
  doc.text(`${customerData.address || 'undefined'}`, 10, 60);
  doc.text(`${customerData.zip_code || 'undefined'} ${customerData.city || 'undefined'} ${customerData.country || 'undefined'}`, 10, 65);
  doc.text(`${customerData.phone || 'undefined'}`, 10, 70);
  doc.text(`${customerData.email || 'undefined'}`, 10, 75);

  // Document Details
  doc.text("Tipo Documento: Scarico Interno", 140, 50);
  doc.text(`Nr: ${documentDetails.document_number}`, 140, 55);
  doc.text(`Data: ${documentDetails.creation_date}`, 140, 60);

  // Table - Document Lines
  const tableColumnHeaders = ["Quantità", "Descrizione", "Prezzo"];
  const tableRows = [];

  inputData.forEach((line) => {
    const row = [
      line.quantity || "1",
      line.description || "",
      line.price ? `€ ${parseFloat(line.price).toFixed(2)}` : "N/A",
    ];
    tableRows.push(row);
  });

  doc.autoTable({
    startY: 90,
    head: [tableColumnHeaders],
    body: tableRows,
    theme: 'grid',
    headStyles: { fillColor: [0, 123, 255] },
    margin: { top: 10 },
  });

  // Totale
  const total = inputData.reduce((acc, line) => acc + (parseFloat(line.price) || 0) * (parseInt(line.quantity) || 1), 0);
  doc.text(`Totale: € ${total.toFixed(2)}`, 150, doc.autoTable.previous.finalY + 20);

  return doc;
};
