// SetupPage.js
import React from 'react';

const SetupPage = () => {
  return (
    <div className="p-10 max-w-3xl mx-auto bg-gray-100 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-8 text-center">Pagina di Setup</h1>
      
      {/* Sezione di Test Tailwind */}
      <div className="bg-blue-200 p-5 rounded-lg mb-5">
        <h2 className="text-xl font-semibold mb-3">Sezione di Test</h2>
        <p className="text-gray-700">
          Questo testo dovrebbe essere visibile con uno sfondo azzurro chiaro, e un padding di 5. 
          Se questo stile non appare correttamente, potrebbe esserci un problema con Tailwind.
        </p>
      </div>
      
      {/* Pulsante di Test */}
      <div className="flex justify-center">
        <button className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300">
          Pulsante di Test
        </button>
      </div>
    </div>
  );
};

export default SetupPage;