import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CustomerPage.css';
import CustomerModal from '../components/CustomerModal'; // Imported the modal component

const CustomerPage = () => {
  const [customers, setCustomers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [message, setMessage] = useState('');  // State to store feedback messages
  const [showMessage, setShowMessage] = useState(false); // Controls message visibility

  const navigate = useNavigate();//Hock navigate to pages

  // Fetch customers from the server
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
        const response = await axios.get(`${serverUrl}/customers`);
        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers', error);
      }
    };

    fetchCustomers();
  }, []);

  // Open modal for editing or creating a new customer
  const openModal = (customer = null) => {
    setCurrentCustomer(customer);  // Set customer for editing, null for new
    setIsModalOpen(true);          // Open modal
  };

  // Close the modal and reset customer data
  const closeModal = () => {
    setIsModalOpen(false);         // Close modal
    setCurrentCustomer(null);      // Reset customer data
    setMessage('');                // Reset message
  };

 // Display the message temporarily
const displayMessage = (msg) => {
  setMessage(msg);                // Set the new message
  setShowMessage(true);            // Show the message
  
  // Hide the message after 3 seconds
  setTimeout(() => {
    setShowMessage(false);       
  }, 2000);
};

  // Handle input changes in the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCustomer({ ...currentCustomer, [name]: value });
  };

  const handleSave = async () => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
  
      console.log('Dati cliente da salvare:', currentCustomer); // Verifica tutti i campi prima di inviare la richiesta
  
      if (currentCustomer.id_customer) {
        // Update an existing customer
        const response = await axios.put(`${serverUrl}/customers/${currentCustomer.id_customer}`, currentCustomer);
        const updatedCustomers = customers.map((customer) =>
          customer.id_customer === currentCustomer.id_customer ? response.data : customer
        );
        setCustomers(updatedCustomers);
        displayMessage('Customer updated successfully.');
      } else {
        // Create a new customer
        const response = await axios.post(`${serverUrl}/customers`, currentCustomer);
        setCustomers([...customers, response.data]);
        displayMessage('Customer created successfully.');
      }
  
      setTimeout(() => {
        closeModal();
      }, 3500);
    } catch (error) {
      console.error('Error saving customer:', error);
      displayMessage('Error saving customer.');
    }
  };
  
  




  // Handle customer deletion
  const handleDelete = async (id_customer) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
      await axios.delete(`${serverUrl}/customers/${id_customer}`);
      setCustomers(customers.filter(customer => customer.id_customer !== id_customer));
      displayMessage(`Customer with ID ${id_customer} deleted.`);
    } catch (error) {
      console.error(`Error deleting customer with ID ${id_customer}`, error);
      displayMessage(`Error deleting customer with ID ${id_customer}.`);
    }
  };

  const handleExit =()=>{navigate('/')};

  return (
    <div className="customer-container">
      <h1>Clienti</h1>

      {/* Feedback message display */}
      {showMessage && (
        <div className={message.includes('Error') ? 'error-message' : 'message'}>
          {message}
        </div>
      )}

      <table className="customer-table">
        <thead>
          <tr>
            <th>Nome Azienda</th>
            <th>Telefono</th>
            <th>Email</th>
            <th>Indirizzo</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {customers.map(customer => (
            <tr key={customer.id_customer}>
              <td>{customer.companyName}</td>
              <td>{customer.phone}</td>
              <td>{customer.email}</td>
              <td>{customer.address}</td>
              <td>
                <button className="edit-btn" onClick={() => openModal(customer)}>
                  <span role="img" aria-label="Modifica cliente">✏️</span>
                </button>
                <button className="delete-btn" onClick={() => handleDelete(customer.id_customer)}>
                  <span role="img" aria-label="Elimina cliente">🗑️</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Footer with Filter and Add Customer buttons */}
      <div className="footer-container">
        <button className="footer-button">Filtra</button>
        <button className="footer-button" onClick={() => openModal()}>Crea Cliente</button>
        <button className="footer-button" onClick={handleExit}>Esci</button>
      </div>

      {/* Modal for creating or editing a customer */}
      <CustomerModal
        customer={currentCustomer}
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={handleSave}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default CustomerPage;
