import React from 'react';
import './DocumentSidebar.css';


const DocumentSidebar = ({ onClose }) => {
    return (
      <div className="document-sidebar">
        <div className="sidebar-content">
          
          <h3>Filtri</h3>
          <div className="form-group">
            <label>Tipo Documento:</label>
            <select>
              <option value="">Tutti</option>
              <option value="DDT">DDT</option>
              <option value="Fattura">Fattura</option>
              <option value="Scarico Interno">Scarico Interno</option>
            </select>
          </div>
          <div className="form-group">
            <label>Data:</label>
            <input type="date" />
          </div>
          <div className="form-group">
            <label>Intestatario:</label>
            <input type="text" placeholder="Inserisci nome intestatario" />
          </div>
          <div className="modal-buttons">
            <button>Applica Filtri</button>
            <button onClick={onClose}>Chiudi</button>
          </div>
        </div>
      </div>
    );
  };
  
  export default DocumentSidebar;
