import React, { useEffect, useState } from 'react';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const InvoiceDocumentData = ({ documentDetails, setDocumentDetails }) => {
  const [paymentTypes, setPaymentTypes] = useState([]);

  useEffect(() => {
    // Fetch the next available invoice number on component mount
    const fetchNextInvoiceNumber = async () => {
      try {
        const response = await fetch(`${serverUrl}/invoices/next-document-number`);
        if (response.ok) {
          const data = await response.json();
          setDocumentDetails((prevDetails) => ({
            ...prevDetails,
            document_number: data.nextDocumentNumber,
          }));
        } else {
          console.error('Errore durante il recupero del prossimo numero di fattura');
        }
      } catch (error) {
        console.error('Errore di connessione durante il recupero del numero di fattura:', error);
      }
    };

    // Fetch payment types
    const fetchPaymentTypes = async () => {
      try {
        const response = await fetch(`${serverUrl}/payments`);
        if (response.ok) {
          const data = await response.json();
          setPaymentTypes(data);
        } else {
          console.error('Errore durante il recupero dei tipi di pagamento');
        }
      } catch (error) {
        console.error('Errore di connessione durante il recupero dei tipi di pagamento:', error);
      }
    };

    fetchNextInvoiceNumber();
    fetchPaymentTypes();
  }, [setDocumentDetails]);

  // Handle changes to document data inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4 text-gray-800">Dati Fattura</h2>
      <form className="space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="form-group">
              <label className="block text-sm font-medium mb-1 text-gray-700">Numero Fattura</label>
              <input
                type="number"
                name="document_number"
                value={documentDetails.document_number || ''}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="form-group">
              <label className="block text-sm font-medium mb-1 text-gray-700">Data Creazione</label>
              <input
                type="date"
                name="creation_date"
                value={documentDetails.creation_date || ''}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div className="space-y-4">
            <div className="form-group">
              <label className="block text-sm font-medium mb-1 text-gray-700">Tipo di Pagamento</label>
              <select
                name="payment_id"
                value={documentDetails.payment_id || ''}
                onChange={(e) => {
                  const selectedPaymentId = e.target.value;
                  const selectedPaymentType = paymentTypes.find(
                    (payment) => payment.payment_id === parseInt(selectedPaymentId)
                  )?.payment_type;

                  setDocumentDetails((prevDetails) => ({
                    ...prevDetails,
                    payment_id: selectedPaymentId,
                    payment_type: selectedPaymentType,
                  }));

                  console.log('Payment ID selezionato:', selectedPaymentId);
                }}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Seleziona un tipo di pagamento</option>
                {paymentTypes.map((payment) => (
                  <option key={payment.payment_id} value={payment.payment_id}>
                    {payment.payment_type}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="form-group mt-4">
          <label className="block text-sm font-medium mb-1 text-gray-700">Note</label>
          <textarea
            name="notes"
            value={documentDetails.notes || ''}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
          />
        </div>
      </form>
    </div>
  );
};

export default InvoiceDocumentData;
