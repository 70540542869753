import React, { useState, useEffect } from 'react';
import setup from '../config/setup.json';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const InvoiceInput = ({ documentLines, setDocumentLines }) => {
  const [inputData, setInputData] = useState({
    quantity: 1,
    id_repair: '',
    description: '',
    price: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [imponibile, setImponibile] = useState(0);
  const aliquota = parseFloat(setup.companyInfo.iva) / 100 || 0.22;

  useEffect(() => {
    // Calculate the imponibile whenever documentLines changes
    const total = documentLines.reduce((acc, line) => acc + parseFloat(line.price || 0) * parseInt(line.quantity || 1), 0);
    setImponibile(total);
  }, [documentLines]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };

  // Handle keypress for repair code input
  const handleRepairCodeKeyPress = async (e) => {
    if (e.key === 'Enter') {
      try {
        const response = await fetch(`${serverUrl}/repairs/${inputData.repairCode}`);
        if (response.ok) {
          const repair = await response.json();

          // Check if the repair is already "Consegnata"
          if (repair.current_status === 'Consegnata') {
            setErrorMessage(`La riparazione con codice ${repair.id_repair} è già stata consegnata e non può essere aggiunta alla fattura.`);
            return;
          }

          // If the repair is not "Consegnata", proceed to add it to the input data
          setInputData((prevInput) => ({
            ...prevInput,
            description: `cod Rip ${repair.id_repair}  ${repair.description}`,
            price: repair.public_price,
          }));

          setErrorMessage(''); // Clear any previous error messages
        } else {
          setErrorMessage('Riparazione non trovata.');
        }
      } catch (error) {
        console.error('Error fetching repair data:', error);
        setErrorMessage('Errore durante la ricerca della riparazione.');
      }
    }
  };

  // Handle adding a line item
  const handleAddLine = () => {
    setDocumentLines((prevLines) => [...prevLines, inputData]);
    // Reset input fields after adding
    setInputData({ quantity: 1, repairCode: '', description: '', price: '' });
  };

  // Handle removing a line item
  const handleRemoveLine = (index) => {
    setDocumentLines((prevLines) => prevLines.filter((_, i) => i !== index));
  };

  const iva = imponibile * aliquota;
  const totale = imponibile + iva;

  return (
    <div className="p-4  bg-white rounded-lg shadow-md space-y-4">
      <div className="flex flex-wrap gap-4 items-end">
        <input
          type="number"
          name="quantity"
          value={inputData.quantity}
          onChange={handleInputChange}
          className="w-20 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Quantità"
        />
        <input
          type="text"
          name="repairCode"
          value={inputData.repairCode}
          onChange={handleInputChange}
          onKeyPress={handleRepairCodeKeyPress}
          className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Codice Riparazione"
        />
        <input
          type="text"
          name="description"
          value={inputData.description}
          onChange={handleInputChange}
          className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Descrizione"
        />
        <input
          type="text"
          name="price"
          value={inputData.price}
          onChange={handleInputChange}
          className="w-32 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Prezzo"
        />
        <button onClick={handleAddLine} className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-700 transition duration-300">
          Aggiungi
        </button>
      </div>

      {/* Error message display */}
      {errorMessage && (
        <div className="mt-2 p-2 text-sm text-red-600 bg-red-100 border border-red-300 rounded">
          {errorMessage}
        </div>
      )}

      {/* Table to display added lines */}
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full bg-white border-collapse border border-gray-200">
          <thead>
            <tr className="bg-blue-50">
              <th className="p-3 text-left border-b border-gray-200">Quantità</th>
              <th className="p-3 text-left border-b border-gray-200">Descrizione</th>
              <th className="p-3 text-left border-b border-gray-200">Prezzo</th>
              <th className="p-3 text-left border-b border-gray-200">Azione</th>
            </tr>
          </thead>
          <tbody>
            {documentLines.map((line, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="p-3 border-b border-gray-200">{line.quantity}</td>
                <td className="p-3 border-b border-gray-200">{line.description}</td>
                <td className="p-3 border-b border-gray-200">€ {line.price}</td>
                <td className="p-3 border-b border-gray-200">
                  <button
                    onClick={() => handleRemoveLine(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Totals section */}
      <div className="mt-5 grid lg:grid-cols-3 gap-4">
        <div className="totals-item">
          <label className="block text-sm font-medium mb-1">Aliquota IVA:</label>
          <input
            type="text"
            value={`${aliquota * 100}%`}
            readOnly
            className="w-full p-2 border border-gray-300 rounded-lg bg-gray-50"
          />
        </div>
        <div className="totals-item">
          <label className="block text-sm font-medium mb-1">Imponibile:</label>
          <input
            type="text"
            value={`€ ${imponibile.toFixed(2)}`}
            readOnly
            className="w-full p-2 border border-gray-300 rounded-lg bg-gray-50"
          />
        </div>
        <div className="totals-item">
          <label className="block text-sm font-medium mb-1">IVA:</label>
          <input
            type="text"
            value={`€ ${iva.toFixed(2)}`}
            readOnly
            className="w-full p-2 border border-gray-300 rounded-lg bg-gray-50"
          />
        </div>
        <div className="totals-item lg:col-span-3">
          <label className="block text-sm font-medium mb-1">Totale:</label>
          <input
            type="text"
            value={`€ ${totale.toFixed(2)}`}
            readOnly
            className="w-full p-2 border border-gray-300 rounded-lg bg-gray-50"
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceInput;
