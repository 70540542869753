// InternalDischargeCustomerData.js
import React, { useState } from 'react';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const InternalDischargeCustomerData = ({ customerData, setCustomerData }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  // Handle input change and search for customer suggestions
  const handleInputChange = async (e) => {
    const searchValue = e.target.value;
    setSearchQuery(searchValue);

    if (searchValue.length > 2) {
      try {
        setIsLoading(true);
        const response = await fetch(`${serverUrl}/customers/search?companyName=${searchValue}`);
        if (response.ok) {
          const data = await response.json();
          setClientSuggestions(data);
          setNotFound(data.length === 0);
        } else {
          setClientSuggestions([]);
          setNotFound(true);
        }
      } catch (error) {
        console.error('Errore durante la ricerca del cliente:', error);
        setClientSuggestions([]);
        setNotFound(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setClientSuggestions([]);
      setNotFound(false);
    }
  };

  // Handle selecting a customer from the list
  const handleSelectCustomer = (customer) => {
    setCustomerData(customer);
    setSearchQuery(customer.companyName);
    setClientSuggestions([]);
    setNotFound(false);
  };

  return (
    <div className="p-5 bg-white rounded-lg shadow-lg">
      <h3 className="text-xl font-semibold mb-4">Dati Cliente</h3>
      <div className="flex items-center space-x-4 mb-5">
        <input
          type="text"
          placeholder="Ricerca rapida cliente"
          value={searchQuery}
          onChange={handleInputChange}
          className="p-3 border rounded-lg flex-grow focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          disabled
          className="p-3 bg-gray-300 text-gray-500 rounded-lg cursor-not-allowed"
        >
          Aggiungi Nuovo Cliente
        </button>
      </div>

      {/* Messaggio di caricamento */}
      {isLoading && <p className="text-blue-500">Caricamento in corso...</p>}

      {/* Lista suggerimenti per la ricerca */}
      {!isLoading && clientSuggestions.length > 0 && (
        <ul className="border border-gray-300 rounded-lg mt-2">
          {clientSuggestions.map((suggestion) => (
            <li
              key={suggestion.id_customer}
              onClick={() => handleSelectCustomer(suggestion)}
              className="p-3 cursor-pointer hover:bg-blue-100"
            >
              {suggestion.companyName}
            </li>
          ))}
        </ul>
      )}

      {/* Messaggio di cliente non trovato */}
      {!isLoading && notFound && (
        <p className="text-red-500 mt-2">Cliente non trovato.</p>
      )}

      {/* Dati cliente selezionato */}
      {customerData && (
        <div className="mt-5 p-4 bg-blue-50 rounded-lg">
          <textarea
            readOnly
            value={`Nome: ${customerData.companyName}\nIndirizzo: ${customerData.address}\nCitta': ${customerData.city}\nEmail: ${customerData.email}`}
            className="w-full h-40 p-3 border rounded-lg bg-gray-100 resize-none"
          />
        </div>
      )}
    </div>
  );
};

export default InternalDischargeCustomerData;
