import React, { useState } from 'react';
import './CustomerModal.css';

const CustomerModal = ({ customer, isOpen, onClose, onSave, onChange }) => {
  const [phoneExists, setPhoneExists] = useState(false); // State to track if the phone number exists
  const [emailError, setEmailError] = useState(''); // State for email validation
  const [pecError, setPecError] = useState(''); // State for PEC validation
  const [phoneError, setPhoneError] = useState(''); // State for phone validation
  const [vatError, setVatError] = useState(''); // State for VAT validation (Partita IVA)
  const [fiscalCodeError, setFiscalCodeError] = useState(''); // State for fiscal code validation (Codice Fiscale)
  const [capError, setCapError] = useState(''); // State for CAP validation
  const [cittaError, setCittaError] = useState(''); // State for city validation
  const [provinciaError, setProvinciaError] = useState(''); // State for province validation
  const [codiceSdiError, setCodiceSdiError] = useState(''); // State for codice SDI validation
  const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

  // Function to handle phone input changes and check if the number exists
  const handlePhoneChange = async (e) => {
    const newPhone = e.target.value;
    const phonePattern = /^[0-9]*$/; // Allow only numbers
    if (!phonePattern.test(newPhone)) {
      setPhoneError('Inserire solo numeri');
    } else {
      setPhoneError('');
    }
    onChange(e);
    if (newPhone.length >= 5) {
      try {
        const response = await fetch(`${serverUrl}/customers/check-phone?phone=${newPhone}`);
        const data = await response.json();
        setPhoneExists(data.exists); // Set the state based on whether the phone exists
      } catch (error) {
        console.error('Error checking phone number:', error);
      }
    } else {
      setPhoneExists(false); // Reset if the phone number is too short
    }
  };

  // Function to handle email validation
  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setEmailError('Formato email non valido');
    } else {
      setEmailError('');
    }
    onChange(e);
  };

  // Function to handle VAT (Partita IVA) validation
  const handleVatChange = (e) => {
    const vat = e.target.value;
    const vatPattern = /^[0-9]{11}$/; // Italian VAT number has 11 digits
    if (!vatPattern.test(vat)) {
      setVatError('Partita IVA non valida (11 numeri)');
    } else {
      setVatError('');
    }
    onChange(e);
  };

  // Function to handle fiscal code (Codice Fiscale) validation
  const handleFiscalCodeChange = (e) => {
    const fiscalCode = e.target.value;
    const vat = customer?.vatNumber || '';

    if (fiscalCode !== vat && fiscalCode.length !== 16) {
      setFiscalCodeError('Codice Fiscale non valido (deve avere 16 caratteri o essere uguale alla Partita IVA)');
    } else {
      setFiscalCodeError('');
    }
    onChange(e);
  };

  // Function to handle PEC validation
  const handlePecChange = (e) => {
    const pec = e.target.value;
    const pecPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!pecPattern.test(pec)) {
      setPecError('Formato PEC non valido');
    } else {
      setPecError('');
    }
    onChange(e);
  };

  // Function to handle CAP (Postal Code) validation
  const handleCapChange = (e) => {
    const cap = e.target.value;
    const capPattern = /^[0-9]{5}$/; // Italian CAP has 5 digits
    if (!capPattern.test(cap)) {
      setCapError('CAP non valido (5 numeri)');
    } else {
      setCapError('');
    }
    onChange(e);
  };

  // Function to handle city validation
  const handleCityChange = (e) => {
    const city = e.target.value;
    if (!city) {
      setCittaError('Inserire la città');
    } else {
      setCittaError('');
    }
    onChange(e);
  };

  // Function to handle province validation
  const handleProvinceChange = (e) => {
    const province = e.target.value;
    if (province.length > 50) {
      setProvinciaError('La provincia deve avere massimo 50 caratteri');
    } else {
      setProvinciaError('');
    }
    onChange(e);
  };

  // Function to handle Codice SDI validation
  const handleCodiceSdiChange = (e) => {
    const codiceSdi = e.target.value;
    const codiceSdiPattern = /^[A-Za-z0-9]{7}$/; // Codice SDI is typically 7 alphanumeric characters
    if (!codiceSdiPattern.test(codiceSdi)) {
      setCodiceSdiError('Codice SDI non valido (7 caratteri alfanumerici)');
    } else {
      setCodiceSdiError('');
    }
    onChange(e);
  };

  if (!isOpen) return null; // If modal is not open, return null and don't render anything

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{customer?.id_customer ? 'Modifica Cliente' : 'Crea Cliente'}</h2>
        <form className="customer-form">
          <div className="form-group">
            <label>Nome</label>
            <input
              type="text"
              name="companyName"
              value={customer?.companyName || ''}
              onChange={onChange}
              placeholder="Nome"
            />
          </div>
          <div className="form-group">
            <label>Telefono</label>
            <input
              type="text"
              name="phone"
              value={customer?.phone || ''}
              onChange={handlePhoneChange}
              placeholder="Telefono"
              className={phoneExists || phoneError ? 'input-error' : ''}
            />
            {phoneExists && <p className="error-message">Il numero di telefono esiste già!</p>}
            {phoneError && <p className="error-message">{phoneError}</p>}
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={customer?.email || ''}
              onChange={handleEmailChange}
              placeholder="Email"
              className={emailError ? 'input-error' : ''}
            />
            {emailError && <p className="error-message">{emailError}</p>}
          </div>

          <div className="form-group">
            <label>PEC</label>
            <input
              type="email"
              name="pec"
              value={customer?.pec || ''}
              onChange={handlePecChange}
              placeholder="PEC"
              className={pecError ? 'input-error' : ''}
            />
            {pecError && <p className="error-message">{pecError}</p>}
          </div>

          <div className="form-group">
            <label>Indirizzo</label>
            <input
              type="text"
              name="address"
              value={customer?.address || ''}
              onChange={onChange}
              placeholder="Indirizzo"
            />
          </div>

          <div className="form-group">
            <label>CAP</label>
            <input
              type="text"
              name="cap"
              value={customer?.cap || ''}
              onChange={handleCapChange}
              placeholder="CAP"
              className={capError ? 'input-error' : ''}
            />
            {capError && <p className="error-message">{capError}</p>}
          </div>

          <div className="form-group">
            <label>Città</label>
            <input
              type="text"
              name="citta"
              value={customer?.citta || ''}
              onChange={handleCityChange}
              placeholder="Città"
              className={cittaError ? 'input-error' : ''}
            />
            {cittaError && <p className="error-message">{cittaError}</p>}
          </div>

          <div className="form-group">
            <label>Provincia</label>
            <input
              type="text"
              name="provincia"
              value={customer?.provincia || ''}
              onChange={handleProvinceChange}
              placeholder="Provincia"
              className={provinciaError ? 'input-error' : ''}
            />
            {provinciaError && <p className="error-message">{provinciaError}</p>}
          </div>

          <div className="form-group">
            <label>Nazione</label>
            <input
              type="text"
              name="nazione"
              value={customer?.nazione || ''}
              onChange={onChange}
              placeholder="Nazione (codice a 3 lettere)"
            />
          </div>

          <div className="form-group">
            <label>Partita IVA</label>
            <input
              type="text"
              name="vatNumber"
              value={customer?.vatNumber || ''}
              onChange={handleVatChange}
              placeholder="Partita IVA"
              className={vatError ? 'input-error' : ''}
            />
            {vatError && <p className="error-message">{vatError}</p>}
          </div>

          <div className="form-group">
            <label>Codice Fiscale</label>
            <input
              type="text"
              name="taxCode"
              value={customer?.taxCode || ''}
              onChange={handleFiscalCodeChange}
              placeholder="Codice Fiscale"
              className={fiscalCodeError ? 'input-error' : ''}
            />
            {fiscalCodeError && <p className="error-message">{fiscalCodeError}</p>}
          </div>

          <div className="form-group">
            <label>Codice SDI</label>
            <input
              type="text"
              name="codiceSdi"
              value={customer?.codiceSdi || ''}
              onChange={handleCodiceSdiChange}
              placeholder="Codice SDI"
              className={codiceSdiError ? 'input-error' : ''}
            />
            {codiceSdiError && <p className="error-message">{codiceSdiError}</p>}
          </div>

          <div className="modal-buttons">
            <button type="button" onClick={onSave} disabled={phoneExists || emailError || pecError || vatError || fiscalCodeError || capError || cittaError || provinciaError || codiceSdiError}>Salva</button>
            <button type="button" onClick={onClose}>Annulla</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerModal;
