import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();



  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
        name,
        password,
      });
  
      // Salva il token JWT
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
  
      // Aggiungi un log dopo aver salvato il token
      console.log('Token JWT salvato:', localStorage.getItem('accessToken'));
  
      setMessage('Login effettuato con successo!');
      navigate('/home'); // Reindirizza alla homepage
      console.log('Tentativo di navigazione verso /home');
    } catch (error) {
      console.error('Dettagli errore:', error.response || error);
      setMessage('Errore durante il login. Credenziali non valide.');
    }
  };
  
  return (
    <div className="login-container flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-3xl mb-5">Login</h1>
      <form className="w-full max-w-xs bg-white p-6 rounded-lg shadow-lg" onSubmit={handleLogin}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Nome Utente</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
            required
          />
        </div>
        {message && <p className="text-red-500 mb-4">{message}</p>}
        <button type="submit" className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600">
          Accedi
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
