// src/pages/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="home-page flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-10 text-blue-700">Gestione 
      Ticket</h1>
      <div className="buttons-container grid grid-cols-2 gap-8 md:grid-cols-2 lg:grid-cols-2">
        <Link to="/clienti">
          <button className="home-button w-56 h-56 text-2xl bg-blue-500 text-white rounded-3xl shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out">
            Clienti
          </button>
        </Link>
        <Link to="/Riparazioni">
          <button className="home-button w-56 h-56 text-2xl bg-green-500 text-white rounded-3xl shadow-lg hover:bg-green-700 transition duration-300 ease-in-out">
            Riparazioni
          </button>
        </Link>
        <Link to="/Documenti">
          <button className="home-button w-56 h-56 text-2xl bg-yellow-500 text-white rounded-3xl shadow-lg hover:bg-yellow-700 transition duration-300 ease-in-out">
            Documenti
          </button>
        </Link>
        <button className="home-button w-56 h-56 text-2xl bg-red-500 text-white rounded-3xl shadow-lg hover:bg-red-700 transition duration-300 ease-in-out">
          Statistiche
        </button>
      </div>
    </div>
  );
};

export default HomePage;
