// InvoicePDF.js
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import setup from '../config/setup.json';

export const generateInvoicePDF = (invoiceData, customerData) => {
  const doc = new jsPDF();

  // Header - Azienda Mittente
  const { name, address, city, province, cap, country, vatInfo } = setup.companyInfo;
  doc.setFontSize(14);
  doc.text(name, 10, 10);
  doc.setFontSize(12);
  doc.text(`${address}, ${cap}`, 10, 15);
  doc.text(`${city} ${province}`, 10, 20);
  doc.text(country, 10, 25);
  doc.text(`P.IVA e C.F.: ${vatInfo}`, 10, 30);

  // Customer Info
  doc.setFontSize(12);
  doc.text('Intestatario', 10, 50);
  doc.text(`${customerData.companyName || ''}`, 10, 55);
  doc.text(`${customerData.address || ''}`, 10, 60);
  doc.text(`${customerData.cap || ''} ${customerData.citta || ''} ${customerData.provincia || ''}`, 10, 65);
  doc.text(`${customerData.nazione || ''}`, 10, 70);
  doc.text(`Codice Fiscale: ${customerData.taxCode || ''}`, 10, 75);
  doc.text(`Partita IVA: ${customerData.vatNumber || ''}`, 10, 80);
  

  // Document Details
  doc.text('Tipo Documento: Fattura', 140, 50);
  doc.text(`Nr: ${invoiceData.document_number}`, 140, 55);
  doc.text(`Data: ${invoiceData.creation_date}`, 140, 60);
  doc.text(`Tipo di Pagamento: ${invoiceData.payment_type}`, 10, 90);


  // Table - Document Lines
  const tableColumnHeaders = ['Quantità', 'Descrizione', 'Prezzo'];
  const tableRows = [];

  invoiceData.lines.forEach((line) => {
    const row = [
      line.quantity || '1',
      line.description || '',
      line.price ? `€ ${parseFloat(line.price).toFixed(2)}` : 'N/A',
    ];
    tableRows.push(row);
  });

  // Auto-table for document lines
  doc.autoTable({
    startY: 110,
    head: [tableColumnHeaders],
    body: tableRows,
    theme: 'grid',
    headStyles: { fillColor: [0, 123, 255] },
    margin: { top: 10 },
  });

  // Totals Section - Adding more space to avoid overlap
  const finalY = doc.autoTable.previous.finalY + 20; // Adding extra space after table to avoid overlap
  doc.text('Totale imponibile:', 120, finalY);
  doc.text(`€ ${parseFloat(invoiceData.price).toFixed(2)}`, 180, finalY, { align: 'right' });
  doc.text('IVA:', 140, finalY + 10);
  doc.text(`€ ${parseFloat(invoiceData.vat).toFixed(2)}`, 180, finalY + 10, { align: 'right' });
  doc.text('Totale:', 140, finalY + 20);
  doc.text(`€ ${parseFloat(invoiceData.total_price).toFixed(2)}`, 180, finalY + 20, { align: 'right' });

  // Notes Section (for DDT references)
  doc.text('Note:', 10, finalY + 40);
  doc.text(`${invoiceData.notes}`, 10, finalY + 50);

  // Open PDF in a new tab for user action
  return doc.output('blob');
};
