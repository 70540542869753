// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SidebarApp from './components/SidebarApp';
import CustomerPage from './pages/CustomerPage';
import RiparazioniPage from './pages/RiparazioniPage';
import DocumentsPage from './pages/DocumentsPage';
import DDTPage from './pages/DDTPage';
import InvoicePage from './pages/InvoicePage';
import InternalDischargePage from './pages/InternalDischargePage';
import SetupPage from './pages/Setup';
import LoginPage from './pages/LoginPage';  // Importa il componente LoginPage
import PrivateRoute from './components/PrivateRoute';  // Importa il componente PrivateRoute

import './App.css';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <div className={`App ${isSidebarOpen ? 'shift' : ''}`}>
        <SidebarApp toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
        <div className={`content ${isSidebarOpen ? 'shift' : ''}`}>
          <Routes>
            {/* Rotta per il login */}
            <Route path="/login" element={<LoginPage />} />

            {/* Rotte protette */}
            <Route 
              path="/home" 
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/clienti" 
              element={
                <PrivateRoute>
                  <CustomerPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/riparazioni" 
              element={
                <PrivateRoute>
                  <RiparazioniPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/documenti" 
              element={
                <PrivateRoute>
                  <DocumentsPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/ddt" 
              element={
                <PrivateRoute>
                  <DDTPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/invoice" 
              element={
                <PrivateRoute>
                  <InvoicePage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/internalDischarge" 
              element={
                <PrivateRoute>
                  <InternalDischargePage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/invoice/:document_number" 
              element={
                <PrivateRoute>
                  <InvoicePage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/ddt/:document_number" 
              element={
                <PrivateRoute>
                  <DDTPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/setup" 
              element={
                <PrivateRoute>
                  <SetupPage />
                </PrivateRoute>
              } 
            />

            {/* Rotta di default */}
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
