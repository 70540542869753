import dayjs from 'dayjs';

export const printRepairs = (repairs) => {
  // Crea una nuova finestra per la stampa
  const printWindow = window.open('', '', 'width=800,height=600');
  
  // Aggiungi contenuti HTML che vuoi stampare
  printWindow.document.write('<html><head><title>Stampa Riparazioni</title></head><body>');
  printWindow.document.write('<h1>Riparazioni</h1>');

  // Creiamo una tabella con le colonne desiderate
  printWindow.document.write('<table border="1" style="width:100%; text-align:left;">');
  printWindow.document.write('<thead><tr><th>ID</th><th>Codice Esterno</th><th>Data Accettazione</th><th>Prezzo Pubblico</th></tr></thead>');
  printWindow.document.write('<tbody>');

  // Iteriamo attraverso le riparazioni per estrarre i dati da stampare
  repairs.forEach(repair => {
    printWindow.document.write('<tr>');
    printWindow.document.write(`<td>${repair.id_repair}</td>`);
    printWindow.document.write(`<td>${repair.externalCode || ''}</td>`);
    printWindow.document.write(`<td>${repair.entry_date ? dayjs(repair.entry_date).format('DD-MM-YYYY') : 'N/A'}</td>`);
    printWindow.document.write(`<td>${repair.public_price || 'N/A'}</td>`);
    printWindow.document.write('</tr>');
  });

  printWindow.document.write('</tbody></table>');
  printWindow.document.write('</body></html>');

  printWindow.document.close(); // Necessario per chiudere il flusso di scrittura
  printWindow.focus();

  // Aspetta che la finestra sia caricata completamente e stampi
  printWindow.onload = () => {
    printWindow.print();

    // Imposta un ritardo prima di chiudere la finestra per garantire che la stampa sia completata
    setTimeout(() => {
      printWindow.close();
    }, 1000); // Attendere un secondo prima di chiudere la finestra
  };
};
