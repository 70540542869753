import React, { useEffect } from 'react';
import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const DDTDocumentData = ({ documentDetails, setDocumentDetails }) => {
  // Funzione per aggiornare i dettagli del documento
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // useEffect per ottenere il prossimo numero di documento all'avvio del componente
  useEffect(() => {
    const fetchNextDocumentNumber = async () => {
      try {
        const response = await axios.get(`${serverUrl}/ddts/next-document-number`);
        if (response.status === 200 && response.data.nextDocumentNumber) {
          setDocumentDetails((prevDetails) => ({
            ...prevDetails,
            document_number: response.data.nextDocumentNumber,
          }));
        }
      } catch (error) {
        console.error('Errore durante il recupero del prossimo numero di documento:', error);
      }
    };

    fetchNextDocumentNumber();
  }, [setDocumentDetails]);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 text-center">Dati Documento</h2>
      <form className="grid gap-6 grid-cols-1 lg:grid-cols-2">
        {/* Colonna sinistra */}
        <div className="space-y-1">
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Numero Documento</label>
            <input
              type="number"
              name="document_number"
              value={documentDetails?.document_number || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Causale Trasporto</label>
            <select
              name="transport_reason"
              value={documentDetails?.transport_reason || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Reso">Reso</option>
              <option value="Riparazione">Riparazione</option>
              <option value="C/Lavorazione">C/Lavorazione</option>
              <option value="Reso da c/lavorazione">Reso da c/lavorazione</option>
            </select>
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Peso (kg)</label>
            <input
              type="number"
              name="weight"
              value={documentDetails?.weight || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Trasporto a cura del</label>
            <select
              name="transport_by"
              value={documentDetails?.transport_by || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Mittente">Mittente</option>
              <option value="Destinatario">Destinatario</option>
              <option value="Vettore">Vettore</option>
            </select>
          </div>
        </div>

        {/* Colonna destra */}
        <div className="space-y-1">
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Data</label>
            <input
              type="date"
              name="creation_date"
              value={documentDetails?.creation_date || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Numero Colli</label>
            <input
              type="number"
              name="number_of_packages"
              value={documentDetails?.number_of_packages || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Porto</label>
            <select
              name="shipment_port"
              value={documentDetails?.shipment_port || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Assegnato">Assegnato</option>
              <option value="Franco">Franco</option>
            </select>
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Corriere</label>
            <select
              name="courier"
              value={documentDetails?.courier || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Nessuno">Nessuno</option>
              <option value="DHL">DHL</option>
              <option value="BRT">BRT</option>
              <option value="GLS">GLS</option>
              <option value="Poste Italiane">Poste Italiane</option>
              <option value="Fedex">Fedex</option>
            </select>
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Aspetto dei beni esteriori</label>
            <select
              name="appearance_of_goods"
              value={documentDetails?.appearance_of_goods || ''}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="A vista">A vista</option>
              <option value="Busta">Busta</option>
              <option value="Borsa">Borsa</option>
              <option value="Scatola">Scatola</option>
            </select>
          </div>
        </div>
      </form>

      {/* Note */}
      <div className="form-group mt-6">
        <label className="block text-sm font-medium mb-1 text-gray-700">Note</label>
        <textarea
          name="notes"
          value={documentDetails?.notes || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
      </div>
    </div>
  );
};

export default DDTDocumentData;
